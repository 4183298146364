/* src/LandingPage.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
    direction: ltr;
  }
  
  .navbar {
    background-color: #333;
    overflow: hidden;
    padding: 10px 20px;
  }
  
  .navbar .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }
  
  .navbar .menu li {
    margin-right: 20px;
  }
  
  .navbar .menu li a {
    color: white;
    text-decoration: none;
    font-size: 1.2em;
    transition: color 0.3s;
  }
  
  .navbar .menu li a:hover {
    color: #00aaff;
  }
  
  .landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .landing-page .content {
    max-width: 600px;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .landing-page .content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    direction: ltr;
  }
  
  .landing-page .content p {
    direction: ltr;
    font-size: 1.2em;
    margin-bottom: 30px;
    color: #555;
  }
  
  .landing-page .content button {
    padding: 15px 30px;
    font-size: 1.2em;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .landing-page .content button:hover {
    background-color: #0056b3;
  }
  